<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Employee Risk Predictor (ERP)
      </h2>

      <img
        src="../../assets/img/tools_bg1.jpg"
        alt="Employee Risk Predictor (ERP) Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="pt-8 pb-4">
          Are you tired of losing your top talent to your competitors?
          Do you wish you had a crystal ball to foresee which employees
          might be thinking of leaving? In today's competitive landscape,
          losing valuable employees can be devastating. But fret not!
          <b>MRC Employee Risk Predictor (ERP)</b> combines the power of
          advanced analytics and machine learning to accurately forecast
          which employees are likely to leave,
          giving you the power to take proactive steps.
        </p>
        <h4 class="pt-4 font-medium text-lg">
          📈 Unlock the Power of Predictive Analytics:
        </h4>
        <p class="pt-2 pb-4">
          Say goodbye to guesswork and hello to data-driven decision-making!
          Our app utilizes <b>machine learning</b> to analyse a wide range of factors.
          By crunching the numbers, we generate accurate predictions about who
          might be on the verge of seeking new opportunities.
        </p>
        <h4 class="pt-4 font-medium text-lg">
          💡 Test, Refine, and Maximize Success:
        </h4>
        <p class="pt-2 pb-4">
          Why leave your retention strategy to chance? With our <b>simulation</b>
          functionality, you can experiment with different interventions, packages,
          and incentives before implementing them in your organization.
          Discover the most effective approaches, refine your tactics, and
          maximize your chances of success in retaining top talent.
        </p>
        <h4 class="pt-4 font-medium text-lg">
          📊 Comprehensive Analytics Dashboard:
        </h4>
        <p class="pt-2 pb-2">
          Visualize your employee retention landscape like never before!
          Our intuitive <b>dashboard</b> presents you with easy-to-understand analytics
          and visual representations of key metrics. Uncover patterns, track progress,
          and make informed decisions to shape a resilient, high-performing workforce.
        </p>
        <p class="pt-2 pb-4">
          Are you ready to transform your workforce retention strategy and build an
          unstoppable team? Don't let your valuable talent slip away.
          Get <b>MRC Employee Risk Predictor (ERP)</b> and unlock the power of
          predictive analytics. Act now and revolutionize your retention game!
        </p>
        <img
        src="../../assets/img/erp_stimulate.jpg"
        alt="Employee Risk Predictor (ERP) Simulate"
        class="object-cover w-full lg:w-3/4 md:w-4/5 sm:p-4 content-center"
      />
      <img
        src="../../assets/img/erp_analytics.jpg"
        alt="Employee Risk Predictor (ERP) Analytics"
        class="object-cover w-full lg:w-3/4 md:w-4/5 sm:p-4 content-center"
      />
      <br/>
      <br/>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
