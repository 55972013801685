import { render, staticRenderFns } from "./EmployeeRiskPredictor.vue?vue&type=template&id=5f692e1b&scoped=true&"
import script from "./EmployeeRiskPredictor.vue?vue&type=script&lang=js&"
export * from "./EmployeeRiskPredictor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f692e1b",
  null
  
)

export default component.exports